@import "../Ball/ball.scss";

.workspace-ball {
  color: white;

  &.square {
    @extend .ball;

    font-weight: 500;
    font-size: 2.5rem;
    border-radius: 0;
    width: initial;
    height: initial;
  }
}
